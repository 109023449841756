@charset "UTF-8";
/* =================================================================
 License : e-TRUST Inc.
 File name : assets.css
 Style : assets.css
================================================================= */
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:100,300,400,500,700,900|Noto+Serif+JP:200,300,400,500,600,700,900&amp;subset=japanese);
.flex {
  display: flex;
}

.jcss {
  justify-content: start;
}

.jcsc {
  justify-content: center;
}

.jcsb {
  justify-content: space-between;
}

.aic {
  align-items: center;
}

.ais {
  align-items: start;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

@media only screen and (max-width: 640px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .disnone {
    display: block !important;
    position: fixed;
    top: 1%;
    left: 2%;
    z-index: 9;
  }
  .disnone a {
    color: #fff;
  }
}

@media only screen and (max-width: 640px) {
  .spdisnone {
    display: none !important;
  }
}

.tdpd10 td {
  padding: 10px !important;
}

li {
  list-style: none;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 400;
}

body {
  counter-reset: number 0;
  line-height: 1.7em;
  font-weight: 700;
  letter-spacing: 0.1em;
  color: #333;
  font-style: normal;
  word-wrap: break-word;
  overflow: hidden;
  font-size: 16px;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

.inner {
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 0 20px;
}

@media only screen and (max-width: 640px) {
  .inner {
    padding: 20px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .inner {
    padding: 64px 0 20px;
  }
}

.inner2 {
  max-width: 1000px;
  margin: 0 auto;
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
  margin-bottom: 100px;
}

#contents {
  width: 100%;
  max-width: 1200px;
}

@media only screen and (max-width: 834px) {
  #contents {
    margin-bottom: 50px !important;
  }
}

@media only screen and (max-width: 834px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  #contents {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map {
  margin: 56px 20px 0;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.pdtd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

small {
  font-size: 80%;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  text-decoration: none;
  transition: 1s;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

.imgR, .imgL {
  max-width: 100% !important;
  float: none;
  margin-left: 0;
}

@media only screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 10px;
    max-width: 100% !important;
    text-align: center;
  }
}

.imgL {
  margin: 0;
  float: none;
  max-width: 100% !important;
}

.mincho {
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media only screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
  text-align: center;
}

#header {
  width: 100%;
}

.telwrap {
  margin-left: 15px;
}

@media only screen and (max-width: 640px) {
  .telwrap {
    width: 45%;
    margin-left: 0;
    padding: 5px;
    line-height: 1;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .telwrap {
    width: 45%;
    margin-right: 5px;
    padding: 5px;
    margin-left: 0;
  }
}

.telwrap .time {
  color: #fff;
  font-size: 13px;
}

@media screen and (min-width: 834px) and (max-width: 1280px) {
  .telwrap .time {
    color: #333;
  }
}

@media only screen and (max-width: 834px) {
  .telwrap .time {
    color: #333;
    font-size: 10px;
  }
}

#header_top {
  border-bottom: 1px solid #ddd;
}

#header_top #header_top_inner {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 6px 0;
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  #header_top #header_top_inner {
    padding: 6px 20px;
  }
}

@media only screen and (max-width: 640px) {
  #header_top #header_top_inner {
    padding: 6px 10px;
  }
}

#header_top #header_top_inner p {
  font-size: 13px;
  text-align: left;
}

@media only screen and (max-width: 640px) {
  #header_top #header_top_inner p {
    line-height: 1.4;
  }
}

#header_top #header_top_inner ul {
  color: #aaa;
  font-size: 13px;
}

@media only screen and (max-width: 640px) {
  #header_top #header_top_inner ul {
    display: none;
  }
}

#header_top #header_top_inner ul li {
  display: inline-block;
}

#header_top #header_top_inner ul li a {
  color: #565656;
  transition: .4s;
}

#header_top #header_top_inner ul li a:hover {
  color: #a98652;
}

.hedwrap {
  position: inherit !important;
}

.hedwrap {
  transition: 0.5s ease-in-out;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(3, 57, 135, 0.5);
  padding: 20px 10px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

@media only screen and (max-width: 834px) {
  .hedwrap {
    padding: 15px 10px;
  }
}

.hedwrap.fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  width: 100%;
}

.hedwrap.hide {
  transform: translateY(-200%);
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .hedwrap.hide {
    transform: translateY(-100%);
  }
}

#siteID img {
  width: 80% !important;
}

.hed_left {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 20%;
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .hed_left {
    padding: 20px;
  }
}

@media only screen and (max-width: 834px) {
  .hed_left {
    width: 100%;
    padding: 0;
  }
}

.hed_right {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 40%;
}

@media only screen and (max-width: 834px) {
  .hed_right {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background: #fff;
    padding: 5px 10px;
    justify-content: center;
    width: 100%;
    align-items: center;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .hed_right {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background: #fff;
    padding: 5px 10px;
    justify-content: center;
    width: 100%;
  }
}

@media screen and (min-width: 834px) and (max-width: 1280px) {
  .hed_right {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background: #fff;
    padding: 5px 10px;
    justify-content: center;
    width: 100%;
    align-items: flex-start;
  }
}

.hedcont {
  margin-left: 15px;
  width: 160px;
}

@media only screen and (max-width: 834px) {
  .hedcont {
    margin-left: 0;
    width: 36%;
    margin: 0 auto;
    padding: 5px;
  }
}

@media screen and (min-width: 834px) and (max-width: 1280px) {
  .hedcont {
    margin: 0;
  }
}

.hedcont a {
  background: #10b5dd;
  color: #fff;
  border-radius: 50px;
  display: block;
  padding: 10px;
  transition: .4s;
  font-size: 1.5em;
}

@media only screen and (max-width: 834px) {
  .hedcont a {
    width: 100%;
    margin: auto;
    font-size: 1.2em;
    padding: 15px;
    height: 60px;
  }
}

.hedcont a i {
  margin-right: 7px;
}

#nav_global.fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
}

@media only screen and (max-width: 834px) {
  #nav_global {
    display: none;
  }
}

#nav_global ul {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

#nav_global ul li {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  position: relative;
}

#nav_global ul li a {
  color: #fff;
  display: block;
  padding: 16px 8px;
  position: relative;
  transition: .4s;
  white-space: nowrap;
}

#nav_global ul ul {
  position: absolute;
  width: 215px;
  z-index: 100;
  top: 100%;
  left: -25%;
  display: block;
  background: rgba(255, 255, 255, 0.7);
  text-align: center;
}

#nav_global ul ul li a {
  color: #333;
}

.insta {
  margin: 7px 10px 0 0;
  background: #fff;
  border-radius: 5px;
}

@media only screen and (max-width: 640px) {
  .insta {
    margin-right: 0;
  }
}

.insta i {
  background: linear-gradient(110deg, #ffd800, #ff0000 45%, #ffd800);
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  color: #ff0000;
}

.insta a {
  font-size: 2em;
  display: block;
}

@media only screen and (max-width: 834px) {
  .insta a {
    color: #033987;
    font-size: 50px;
    padding: 5px;
  }
}

@media screen and (min-width: 834px) and (max-width: 1280px) {
  .insta a {
    color: #033987;
    font-size: 60px;
  }
}

.fb {
  margin-top: 7px;
  background: #fff;
  border-radius: 5px;
}

.fb i {
  background: linear-gradient(110deg, #ffd800, #ff0000 45%, #ffd800);
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  color: #ff0000;
}

.fb a {
  font-size: 2em;
  display: block;
}

@media only screen and (max-width: 834px) {
  .fb a {
    color: #033987;
    font-size: 50px;
    padding: 5px;
  }
}

@media screen and (min-width: 834px) and (max-width: 1280px) {
  .fb a {
    color: #033987;
    font-size: 60px;
  }
}

.tel2 {
  width: 263px;
  margin: 0 auto;
  color: #fff;
  background: #033987;
  padding: 10px 15px;
  border-radius: 50px;
  font-size: 1.5em;
}

@media only screen and (max-width: 640px) {
  .tel2 {
    font-size: 1em;
    padding: 0;
    width: 100%;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .tel2 {
    margin-left: 10px;
    margin: 0 10px;
    width: 100%;
  }
}

@media screen and (min-width: 834px) and (max-width: 1280px) {
  .tel2 {
    margin: 0 10px;
  }
}

.tel2 i {
  margin-right: 7px;
  color: #fff;
}

.tel2 a {
  color: #fff;
  display: block;
}

@media only screen and (max-width: 640px) {
  .tel2 a {
    padding: 10px 15px;
  }
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.mainArea {
  width: 100%;
}

.mainArea img {
  border-width: 0px !important;
}

@media only screen and (max-width: 640px) {
  .mainArea img {
    width: 120% !important;
    min-height: 150vw !important;
    margin: 0 -34% !important;
    object-fit: cover;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .mainArea img {
    width: 120% !important;
    min-height: 60vw !important;
    margin: 0 -34% !important;
    object-fit: cover;
  }
}

.mainwrap {
  position: relative;
}

.mainwrap canvas {
  width: 100%;
  height: 300px;
  vertical-align: top;
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
}

.mainwrap .main_txt {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 230px;
  text-align: center;
  line-height: 1.5em;
  width: 100%;
  padding: 30px;
  font-size: 50px;
  color: #fff;
  text-shadow: 5px 5px 10px rgba(3, 57, 135, 0.8);
}

.mainwrap .main_txt h1 {
  font-weight: bold;
}

.mainwrap .main_txt span {
  display: block;
  font-family: "Noto Serif JP", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

@media only screen and (max-width: 640px) {
  .mainwrap .main_txt {
    text-align: center;
    height: 130px;
    width: 100%;
    left: 0;
    font-size: 30px;
  }
}

.mainwrap .main_txt br {
  display: none;
}

@media only screen and (max-width: 834px) {
  .mainwrap .main_txt br {
    display: block;
  }
}

#sp {
  display: none;
}

@media only screen and (max-width: 834px) {
  #sp {
    display: block;
    margin-left: 2em;
  }
}

@media only screen and (max-width: 640px) {
  #sp {
    margin-left: 1em;
  }
}

#nav_sp {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: #fff;
  z-index: 210;
}

.nav_wrap {
  align-items: center;
  display: flex;
  height: 100%;
  position: absolute;
  width: 100%;
  background: url(../images/contents/cont2_bg.jpg) no-repeat;
  background-size: cover;
}

.nav_wrap ul {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  padding: 0 100px;
}

.nav_wrap ul li {
  opacity: 0;
  border-bottom: 1px solid #fff;
  padding: 10px 0;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .nav_wrap ul li {
    padding: 16px 0;
  }
}

@media only screen and (max-width: 640px) {
  .nav_wrap ul li {
    padding: 10px 0;
  }
}

.nav_wrap ul li a {
  color: #fff;
  display: block;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .nav_wrap ul li a {
    font-size: 22px;
  }
}

@media only screen and (max-width: 640px) {
  .nav_wrap ul li a {
    font-size: 18px;
  }
}

.sp_menu {
  width: 24px;
  height: 18px;
  display: block;
  z-index: 300;
  position: relative;
  display: none;
}

@media only screen and (max-width: 834px) {
  .sp_menu {
    display: block;
  }
}

@media only screen and (max-width: 640px) {
  .sp_menu {
    margin-right: 10px;
  }
}

.sp_menu div {
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  background-color: #fff;
}

.sp_menu div:nth-of-type(1) {
  top: 0;
}

.sp_menu div:nth-of-type(2) {
  top: 8px;
  opacity: 1;
}

.sp_menu div:nth-of-type(3) {
  bottom: 0;
}

.sp_menu.off div:nth-of-type(1) {
  -webkit-animation: menu-bar01 .75s forwards;
  animation: menu-bar01 .75s forwards;
}

@-webkit-keyframes menu-bar01 {
  0% {
    -webkit-transform: translateY(8px) rotate(45deg);
  }
  50% {
    -webkit-transform: translateY(8px) rotate(0);
  }
  100% {
    -webkit-transform: translateY(0) rotate(0);
  }
}

@keyframes menu-bar01 {
  0% {
    transform: translateY(8px) rotate(45deg);
  }
  50% {
    transform: translateY(8px) rotate(0);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}

.sp_menu.off div:nth-of-type(2) {
  transition: all .25s .25s;
  -webkit-transition: all .25s .25s;
}

@-webkit-keyframes menu-bar02 {
  0% {
    -webkit-transform: translateY(-8px) rotate(-45deg);
  }
  50% {
    -webkit-transform: translateY(-8px) rotate(0);
  }
  100% {
    -webkit-transform: translateY(0) rotate(0);
  }
}

@keyframes menu-bar02 {
  0% {
    transform: translateY(-8px) rotate(-45deg);
  }
  50% {
    transform: translateY(-8px) rotate(0);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}

.sp_menu.off div:nth-of-type(3) {
  -webkit-animation: menu-bar02 .75s forwards;
  animation: menu-bar02 .75s forwards;
}

.sp_menu.on div {
  background: #fff;
}

.sp_menu.on div:nth-of-type(1) {
  -webkit-animation: active-menu-bar01 .75s forwards;
  animation: active-menu-bar01 .75s forwards;
}

@-webkit-keyframes active-menu-bar01 {
  0% {
    -webkit-transform: translateY(0) rotate(0);
  }
  50% {
    -webkit-transform: translateY(8px) rotate(0);
  }
  100% {
    -webkit-transform: translateY(8px) rotate(45deg);
  }
}

@keyframes active-menu-bar01 {
  0% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(8px) rotate(0);
  }
  100% {
    transform: translateY(8px) rotate(45deg);
  }
}

.sp_menu.on div:nth-of-type(2) {
  opacity: 0;
}

.sp_menu.on div:nth-of-type(3) {
  -webkit-animation: active-menu-bar03 .75s forwards;
  animation: active-menu-bar03 .75s forwards;
}

@-webkit-keyframes active-menu-bar03 {
  0% {
    -webkit-transform: translateY(0) rotate(0);
  }
  50% {
    -webkit-transform: translateY(-8px) rotate(0);
  }
  100% {
    -webkit-transform: translateY(-8px) rotate(-45deg);
  }
}

@keyframes active-menu-bar03 {
  0% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(-8px) rotate(0);
  }
  100% {
    transform: translateY(-8px) rotate(-45deg);
  }
}

#subArea {
  height: 360px;
  position: relative;
}

@media only screen and (max-width: 640px) {
  #subArea {
    height: 280px;
  }
}

#subArea h1 {
  font-size: 40px;
  font-weight: bold;
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  height: 40px;
  line-height: 1;
}

@media only screen and (max-width: 640px) {
  #subArea h1 {
    font-size: 32px;
    height: 32px;
  }
}

.slick-initialized .slick-slide {
  height: auto;
}

#local-keyvisual {
  position: relative;
  background-position: left top;
}

@media only screen and (max-width: 640px) {
  #local-keyvisual {
    margin-bottom: 30px;
  }
}

#local-keyvisual h1 {
  letter-spacing: 0.1em;
  color: #fff;
  font-size: 3em;
  font-weight: 700;
  z-index: 1;
  position: relative;
  text-align: left;
  line-height: 1.3em;
  max-width: 1200px;
  margin: 0 auto;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  #local-keyvisual h1 {
    font-size: 2.5em;
    padding: 50px 0;
    text-align: center;
  }
}

@media only screen and (max-width: 640px) {
  #local-keyvisual h1 {
    font-size: 1.7em;
    padding: 50px 0;
    text-align: center;
  }
}

#local-keyvisual h1 span {
  display: block;
  padding: 100px 10px;
}

@media only screen and (max-width: 834px) {
  #local-keyvisual h1 span {
    padding: 100px 10px 20px;
  }
}

#local-keyvisual h1 span:empty {
  display: none;
}

#local-keyvisual #local-keyvisual-bg, #local-keyvisual .local-keyvisual-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#local-keyvisual-title {
  background: url(../images/contents/cont2_bg.jpg) no-repeat;
  background-size: cover;
}

.pan1 {
  margin: 20px 0 60px 0;
}

@media only screen and (max-width: 640px) {
  .pan1 {
    display: none;
  }
}

.pan1 a {
  color: #000;
}

footer#global_footer {
  width: 100%;
  min-width: 100% !important;
  height: auto;
  background: #033987;
  text-align: center;
}

footer#global_footer .inner {
  padding: 50px 0;
}

@media only screen and (max-width: 834px) {
  footer#global_footer .inner {
    padding: 30px 0;
  }
}

footer#global_footer a {
  text-decoration: none;
}

footer#global_footer #nav_footer {
  margin-bottom: 30px;
  display: flex;
  justify-content: flex-end;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  footer#global_footer #nav_footer {
    display: none;
  }
}

@media only screen and (max-width: 640px) {
  footer#global_footer #nav_footer {
    display: none;
  }
}

footer#global_footer #nav_footer ul {
  display: block;
  width: 100px;
  width: 100%;
}

footer#global_footer #nav_footer li {
  text-align: left;
  width: 185px;
  margin: 0 auto;
}

footer#global_footer #nav_footer li a {
  text-decoration: none;
  padding: 10px;
  display: block;
  color: #fff;
  white-space: nowrap;
}

footer#global_footer #nav_footer li a:hover {
  opacity: 0.6;
}

footer#global_footer #copy a {
  text-decoration: none;
  color: #fff;
}

footer#global_footer #copy a:hover {
  opacity: 0.6;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  footer#global_footer #copy a {
    line-height: 60px;
  }
}

.ftinsta i {
  background: linear-gradient(110deg, #ffd800, #ff0000 45%, #ffd800);
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  color: #ff0000;
}

.ftinsta a {
  color: #fff;
  font-size: 1.5em;
  display: block;
}

.ftfb i {
  background: linear-gradient(110deg, #ffd800, #ff0000 45%, #ffd800);
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  color: #ff0000;
}

.ftfb a {
  color: #fff;
  font-size: 1.5em;
  display: block;
}

.makerwrap {
  background: #fff;
}

@media only screen and (max-width: 640px) {
  .makerwrap img {
    width: 70% !important;
    height: 70% !important;
    object-fit: cover;
    font-family: 'object-fit: cover;';
  }
}

.makerwrap span {
  display: block;
  font-size: 10px;
}

@media only screen and (max-width: 834px) {
  .makerwrap {
    padding: 0 10px;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .makerwrap {
    padding: 0 10px;
  }
}

.makerwrap .makerh2 h2 {
  text-align: left;
  font-size: 1.6em;
  font-weight: bold;
  color: #033987;
  position: relative;
  margin-bottom: 30px;
}

.makerwrap .makerh2 h2:after {
  content: '';
  position: absolute;
  top: 50%;
  display: inline-block;
  width: 80%;
  height: 1px;
  background-color: #033987;
  right: 0;
}

@media only screen and (max-width: 834px) {
  .makerwrap .makerh2 h2:after {
    width: 58%;
  }
}

.makerwrap ul {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
}

.makerwrap ul li {
  width: 13%;
}

@media only screen and (max-width: 834px) {
  .makerwrap ul li {
    width: 17%;
  }
}

.footer2 {
  padding: 140px 0;
  background: url(../images/common/cont_bg.jpg) center;
  background-size: cover;
}

.footer2 .fth2 {
  margin-bottom: 50px;
}

@media only screen and (max-width: 834px) {
  .footer2 .fth2 {
    margin-bottom: 30px;
  }
}

.footer2 .fth2 h2 {
  font-size: 1.8em;
  font-weight: bold;
  position: relative;
  color: #fff;
}

.footer2 .fth2 h2 span {
  display: block;
  font-size: 13px;
  font-weight: 400;
}

.footer2 .fttxt {
  margin-bottom: 50px;
  color: #fff;
}

@media only screen and (max-width: 834px) {
  .footer2 .fttxt {
    margin-bottom: 30px;
  }
}

.footer2 .ftwrap {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 640px) {
  .footer2 .ftwrap {
    display: block;
    padding: 10px;
  }
}

.footer2 .ftwrap .fttelwrap .time {
  color: #fff;
  font-size: 13px;
}

.footer2 .ftwrap .tel2 {
  width: 250px;
  color: #fff;
  background: #033987;
  font-size: 1.6em;
  padding: 15px;
  margin: 0 30px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  white-space: nowrap;
}

@media only screen and (max-width: 640px) {
  .footer2 .ftwrap .tel2 {
    padding: 0;
    margin: auto;
  }
}

.footer2 .ftwrap .tel2 i {
  margin-right: 7px;
}

.footer2 .ftwrap .tel2 a {
  color: #fff;
}

@media only screen and (max-width: 640px) {
  .footer2 .ftwrap .tel2 a {
    padding: 15px;
  }
}

.footer2 .ftwrap .ftcont a {
  width: 250px;
  color: #fff;
  background: #10b5dd;
  font-size: 1.25em;
  padding: 15px;
  display: block;
  border-radius: 50px;
}

@media only screen and (max-width: 640px) {
  .footer2 .ftwrap .ftcont a {
    margin: 15px auto;
  }
}

.footer2 .ftwrap .ftcont a i {
  margin-right: 7px;
}

.small_bg {
  background: #033987;
}

#copy {
  color: #fff;
}

.pagetop {
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 2;
  animation: vertical 1s ease-in-out infinite alternate;
}

.pagetop a {
  animation: vertical 1s ease-in-out infinite alternate;
}

@media only screen and (max-width: 834px) {
  .pagetop {
    bottom: 10%;
    right: -2%;
    width: 20%;
  }
}

@media screen and (min-width: 834px) and (max-width: 1280px) {
  .pagetop {
    bottom: 10%;
    right: -2%;
  }
}

#footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  #footer {
    display: block;
    text-align: center;
  }
}

.ft_L {
  text-align: left;
  width: 48%;
  color: #fff;
}

.ft_L .ftlogo {
  font-size: 1.5em;
}

@media only screen and (max-width: 834px) {
  .ft_L {
    width: 100%;
    text-align: center;
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .ft_L {
    padding-left: 10px;
  }
}

.ft_L ul li:nth-of-type(1) {
  margin-bottom: 30px;
}

.ft_nav {
  width: 48%;
}

/* box */
.btnlist li a {
  text-align: center;
}

.h2_01 h2 {
  font-size: 2.1em;
  color: #333;
  line-height: 1.2em;
  font-weight: bold;
}

@media only screen and (max-width: 834px) {
  .h2_01 h2 {
    font-size: 24px;
  }
}

.h2_02 h2 {
  font-size: 1.8em;
  color: #fff;
  line-height: 1.2em;
  font-weight: bold;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  .h2_02 h2 {
    font-size: 24px;
  }
}

.h2_03 h2 {
  font-size: 1.8em;
  color: #033987;
  line-height: 1.2em;
  font-weight: bold;
  position: relative;
}

.h2_03 h2 span {
  font-size: 13px;
  display: block;
}

.h2_03 h2:before, .h2_03 h2:after {
  content: '';
  position: absolute;
  top: 50%;
  display: inline-block;
  width: 34%;
  height: 1px;
  background-color: #033987;
}

.h2_03 h2:before {
  left: 0;
}

.h2_03 h2:after {
  right: 0;
}

.h3_01 h3 {
  font-weight: bold;
  font-size: 1.25em;
  color: #fff;
}

@keyframes horizontal {
  0% {
    transform: translateX(-3px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes vertical {
  0% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.boxwrap {
  position: absolute;
  z-index: 2;
  position: absolute;
  bottom: 2%;
  left: 0;
  right: 0;
}

@media only screen and (max-width: 640px) {
  .boxwrap {
    bottom: 10%;
  }
}

.boxwrap2 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.boxwrap2 .box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  width: 48%;
  margin: 10px;
  background: #036eb7;
  border-radius: 10px;
  padding: 30px;
  position: relative;
}

@media only screen and (max-width: 834px) {
  .boxwrap2 .box {
    width: 100% !important;
    display: block;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .boxwrap2 .box {
    width: 46% !important;
  }
}

.boxwrap2 .box:nth-of-type(3), .boxwrap2 .box:nth-of-type(4) {
  margin-top: 50px;
}

@media only screen and (max-width: 834px) {
  .boxwrap2 .box:nth-of-type(1), .boxwrap2 .box:nth-of-type(2) {
    margin-top: 50px;
  }
}

.boxwrap2 .box .flxL {
  width: 48%;
}

@media only screen and (max-width: 834px) {
  .boxwrap2 .box .flxL {
    width: 100% !important;
  }
  .boxwrap2 .box .flxL .img {
    text-align: center;
  }
}

.boxwrap2 .box .flxR {
  width: 52%;
}

@media only screen and (max-width: 834px) {
  .boxwrap2 .box .flxR {
    width: 100% !important;
  }
}

@media only screen and (max-width: 834px) {
  .boxwrap2 .box {
    width: 100% !important;
  }
}

.boxwrap2 .box .txt div {
  color: #fff;
}

.boxwrap2 .box .img_nmb {
  position: absolute;
  top: -43px;
  left: 31px;
}

.tablewrap {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 834px) {
  .tablewrap {
    display: block;
  }
}

.tablewrap .table_txt {
  width: 50%;
}

@media only screen and (max-width: 834px) {
  .tablewrap .table_txt {
    width: 100% !important;
    margin-top: 15px;
  }
}

.tablewrap .table_txt td {
  border: none !important;
}

.tablewrap .table_txt .title div {
  font-size: 1.25em;
  font-weight: bold;
}

.tablewrap .table_txt .title {
  padding: 5px !important;
}

.tablewrap .table_txt .txt {
  width: 70%;
  font-size: 14px;
  padding: 5px !important;
}

.tablewrap .table_txt .img {
  width: 30%;
  padding: 0 !important;
}

.txt > div {
  line-height: 2em;
}

.con100 {
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
}

.newswrap2 {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 834px) {
  .newswrap2 {
    display: block;
  }
  .newswrap2 .flxL {
    width: 100% !important;
  }
  .newswrap2 .flxR {
    width: 100% !important;
  }
}

.newswrap2 .flxL {
  width: 48%;
}

.newswrap2 .flxR {
  width: 48%;
}

.newswrap2 .flxR .fb {
  text-align: center;
}

.newswrap .news {
  height: 500px;
  overflow-y: auto;
  max-width: 1000px;
  margin: 0 auto;
}

.newswrap .news dt {
  border: none !important;
  color: #333;
}

@media only screen and (max-width: 834px) {
  .newswrap .news dt {
    width: 100% !important;
    display: block !important;
  }
}

.newswrap .news dd {
  border: none !important;
  color: #333;
  width: 100% !important;
}

@media only screen and (max-width: 834px) {
  .newswrap .news dd {
    width: 100% !important;
  }
}

.newswrap .news dl {
  border-bottom: 1px dashed #dcdcdc;
  padding: 15px 0 !important;
}

@media only screen and (max-width: 834px) {
  .newswrap .news dl {
    display: block !important;
    width: 100% !important;
  }
}

.btn {
  width: 400px;
  margin: 0 auto;
}

.btn a {
  color: #fff;
  background: #033987;
  padding: 20px;
  display: block;
  font-size: 1.3em;
  white-space: nowrap;
  font-weight: 500;
  position: relative;
  border-radius: 50px;
}

.btn a:after {
  font-family: "Font Awesome 5 pro";
  content: "\f0a9";
  /*アイコンのユニコード*/
  position: absolute;
  /*絶対位置*/
  font-size: 1em;
  /*サイズ*/
  right: 3%;
  /*アイコンの位置*/
  top: 25%;
  /*アイコンの位置*/
  color: #fff;
  /*アイコン色*/
}

.btn2 {
  width: 400px;
  margin: 0 auto;
}

@media only screen and (max-width: 834px) {
  .btn2 {
    width: 100%;
  }
}

.btn2 a {
  color: #fff;
  background: #033987;
  padding: 20px;
  display: block;
  font-size: 1.3em;
  white-space: nowrap;
  font-weight: 500;
  position: relative;
  border-radius: 50px;
}

.btn2 a:after {
  font-family: "Font Awesome 5 pro";
  content: "\f0a9";
  /*アイコンのユニコード*/
  position: absolute;
  /*絶対位置*/
  font-size: 1em;
  /*サイズ*/
  right: 3%;
  /*アイコンの位置*/
  top: 25%;
  /*アイコンの位置*/
  color: #fff;
  /*アイコン色*/
}

.btn3 {
  width: 400px;
  margin: 0 auto;
}

.btn3 a {
  color: #fff;
  background: #033987;
  padding: 15px;
  display: block;
  font-size: 1.3em;
  white-space: nowrap;
  font-weight: 500;
  position: relative;
  border-radius: 50px;
}

.btn3 a:after {
  font-family: "Font Awesome 5 pro";
  content: "\f0a9";
  /*アイコンのユニコード*/
  position: absolute;
  /*絶対位置*/
  font-size: 1em;
  /*サイズ*/
  right: 3%;
  /*アイコンの位置*/
  top: 25%;
  /*アイコンの位置*/
  color: #fff;
  /*アイコン色*/
}

.telbtn {
  line-height: 1.6em;
}

.telbtn .tel2 {
  width: 330px;
  margin: 0 auto;
  color: #fff;
  background: #033987;
  padding: 15px;
  display: block;
  font-size: 1.3em;
  white-space: nowrap;
  font-weight: 500;
  border-radius: 50px;
}

@media only screen and (max-width: 834px) {
  .telbtn .tel2 {
    width: 100%;
  }
}

.sec_01 {
  position: relative;
}

.sec_01:before {
  content: url(../images/common/cont1_icon1.png);
  position: absolute;
  left: -17%;
  top: -17%;
  animation: vertical 1s ease-in-out infinite alternate;
}

@media only screen and (max-width: 834px) {
  .sec_01:before {
    top: -73%;
    left: 0;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .sec_01:before {
    top: -82%;
    left: 0;
  }
}

@media screen and (min-width: 834px) and (max-width: 1480px) {
  .sec_01:before {
    top: -82%;
    left: 0;
  }
}

.sec_01:after {
  content: url(../images/common/cont1_icon2.png);
  position: absolute;
  right: -17%;
  bottom: 30%;
  animation: vertical 1s ease-in-out infinite alternate;
}

@media only screen and (max-width: 834px) {
  .sec_01:after {
    bottom: -70%;
    right: 0;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .sec_01:after {
    bottom: -63%;
    right: 0;
  }
}

@media screen and (min-width: 834px) and (max-width: 1480px) {
  .sec_01:after {
    bottom: -63%;
    right: 0;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .img_bnr {
    margin-top: 285px !important;
  }
}

.off_txt div {
  background: #ff0000;
  color: #fff;
  font-size: 1.5em;
  font-weight: bold;
  padding: 30px;
  max-width: 650px;
  margin: 0 auto;
  border-radius: 10px;
}

.sec_02 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  .sec_02 {
    display: block;
  }
}

@media screen and (min-width: 834px) and (max-width: 1200px) {
  .sec_02 {
    padding: 50px 10px;
  }
}

.sec_02 .flxL {
  width: 50%;
}

@media only screen and (max-width: 834px) {
  .sec_02 .flxL {
    width: 100% !important;
    text-align: center;
  }
}

.sec_02 .flxR {
  width: 50%;
}

.sec_02 .flxR .txt {
  color: #fff;
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .sec_02 .flxR .txt div {
    text-align: center !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .sec_02 .flxR .h2_02 h2 {
    justify-content: center;
  }
}

@media only screen and (max-width: 834px) {
  .sec_02 .flxR {
    width: 100% !important;
    margin-top: 15px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .list {
    max-width: 500px;
    margin: 0 auto;
  }
}

.list li {
  float: none !important;
  display: flex;
  color: #fff;
  display: flex;
  align-items: center;
}

.list li:before {
  content: url(../images/common/check.png);
  padding-right: 30px;
}

@media only screen and (max-width: 640px) {
  .list li {
    font-size: 1em !important;
  }
}

.h2_04 h2 {
  font-size: 1.8em;
  color: #333;
  line-height: 1.2em;
  font-weight: bold;
  background: #d5f5fe;
  padding: 10px;
  position: relative;
  padding-left: 25px;
  border-radius: 10px;
}

.h2_04 h2 span {
  font-size: 18px;
}

@media only screen and (max-width: 834px) {
  .h2_04 h2 span {
    display: block;
  }
}

.h2_04 h2:before {
  content: "";
  width: 5px;
  height: 10px;
  border-radius: 500px;
  background: #033987;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
}

.td_logo {
  width: 15%;
}

@media only screen and (max-width: 640px) {
  .td_logo {
    width: 30%;
  }
}

.sec_03 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 640px) {
  .sec_03 {
    display: block;
  }
  .sec_03 .flxL {
    width: 100% !important;
  }
  .sec_03 .flxR {
    width: 100% !important;
  }
}

.sec_03 .flxL {
  width: 58%;
}

@media only screen and (max-width: 640px) {
  .sec_03 .flxL {
    margin-right: 0;
    width: 100%;
    text-align: center !important;
  }
}

.sec_03 .flxR {
  width: 36%;
}

@media only screen and (max-width: 640px) {
  .sec_03 .flxR {
    width: 100%;
    margin-top: 15px;
  }
}

.sec_04 {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 640px) {
  .sec_04 {
    display: block;
  }
  .sec_04 .flxL {
    width: 100% !important;
  }
  .sec_04 .flxR {
    width: 100% !important;
  }
}

.sec_04 .flxL {
  width: 58%;
}

@media only screen and (max-width: 640px) {
  .sec_04 .flxL {
    margin-right: 0;
    width: 100%;
    text-align: center !important;
  }
}

.sec_04 .flxR {
  width: 36%;
}

@media only screen and (max-width: 640px) {
  .sec_04 .flxR {
    width: 100%;
    margin-top: 15px;
  }
}

.table_cont {
  max-width: 800px;
  margin: 0 auto;
}

.table_cont td {
  border: none !important;
}

.txt01 div {
  font-size: 1.25em;
  font-weight: bold;
  border-bottom: 1px solid #d5f5fe;
  padding-bottom: 10px;
}

.triangle {
  margin: 0 auto;
  width: 0;
  height: 0;
  border-left: 100px solid transparent;
  border-right: 100px solid transparent;
  border-top: 60px solid #ccc;
}

.txt_kanryou {
  max-width: 530px;
  margin: 0 auto;
  background: #f3f3f3;
  padding: 15px;
  font-size: 14px;
  border-radius: 10px;
}

.txt_kanryou span {
  font-size: 1.5em;
  font-weight: bold;
}

.txt_kanryou2 {
  max-width: 530px;
  margin: 0 auto;
  background: #ff0000;
  padding: 15px;
  font-size: 14px;
  border-radius: 10px;
  color: #fff;
}

.txt_kanryou2 span {
  font-size: 1.5em;
  font-weight: bold;
}

.effect {
  transition: .3s;
}

.effect div {
  margin: auto;
  transition: .3s;
  transition-delay: .3s;
}

.td_shiharai {
  max-width: 800px;
  margin: 0 auto;
}

.td_shiharai dt {
  border: none !important;
}

.td_shiharai dd {
  border: none !important;
  text-align: right;
}

.td_shiharai dl {
  border-bottom: 1px dotted #ccc;
}

.accesswrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  .accesswrap {
    display: block;
  }
}

.table_img td {
  border: none !important;
}

.table_price .logo_bn {
  border-bottom: none !important;
}

.table_price .logo_bn2 {
  border-top: none !important;
}

.table_price .td_top {
  background: #033987;
  color: #fff;
}

.table_price .td_side {
  background: #f3f3f3;
}

.table_price td {
  padding: 6px !important;
  font-size: 13px;
}

.midashi div {
  font-size: 1.25em;
  font-weight: bold;
  margin-bottom: 5px;
  max-width: 800px;
  margin: 0 auto;
  color: #fff;
  background: #033987;
  padding: 10px;
  border-radius: 10px;
}

.midashi2 div {
  font-size: 1.25em;
  font-weight: bold;
  margin-bottom: 5px;
  color: #fff;
  background: #033987;
  padding: 10px;
  border-radius: 10px;
}

.midashi2 div span {
  color: #ffdc00;
}

.txt03 span {
  background: #033987;
  color: #fff;
  border-radius: 5px;
  padding: 5px 10px;
  line-height: 2em;
}

.txt04 div {
  color: #ff0000;
  font-size: 14px;
  line-height: 1.3em;
}

.table_access {
  max-width: 650px;
}

.table_access dt {
  border: none !important;
  padding: 5px !important;
}

.table_access a {
  color: #333;
}

.table_access dd {
  border: none !important;
  padding: 2px !important;
}

.table_access dl {
  border-bottom: 1px solid #ccc;
}

.table_gaiyou a {
  color: #333;
}

.table_gaiyou dt {
  background: #f3f3f3 !important;
  padding: 6px !important;
}

@media only screen and (max-width: 640px) {
  .table_gaiyou dt {
    display: block !important;
    width: 100% !important;
  }
}

.table_gaiyou dd {
  padding: 6px !important;
}

@media only screen and (max-width: 640px) {
  .table_gaiyou dd {
    display: block !important;
    width: 100% !important;
  }
}

@media only screen and (max-width: 640px) {
  .table_gaiyou dl {
    display: block !important;
  }
}

.btnlist {
  max-width: 830px;
  margin: 0 auto;
}

@media only screen and (max-width: 834px) {
  .btnlist {
    max-width: 100%;
  }
}

.btnlist li {
  margin: 5px;
}

@media only screen and (max-width: 834px) {
  .btnlist li {
    float: none !important;
  }
}

.keireki_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 834px) {
  .keireki_wrap {
    display: block;
  }
}

.keireki_wrap .table_img {
  width: 40%;
}

@media only screen and (max-width: 834px) {
  .keireki_wrap .table_img {
    width: 100% !important;
  }
}

.keireki_wrap .table_img span {
  font-weight: bold;
}

.keireki_wrap .table_img td {
  border: none !important;
}

.table_keireki {
  width: 60%;
}

@media only screen and (max-width: 834px) {
  .table_keireki {
    width: 100% !important;
  }
}

.table_keireki dt {
  border: none !important;
  border-bottom: 1px solid #033987 !important;
  padding: 2px !important;
}

@media only screen and (max-width: 640px) {
  .table_keireki dt {
    display: block !important;
    width: 100% !important;
  }
}

.table_keireki dd {
  border: none !important;
  border-bottom: 1px solid #d5f5fe !important;
  padding: 2px !important;
}

@media only screen and (max-width: 640px) {
  .table_keireki dd {
    width: 100% !important;
  }
}

@media only screen and (max-width: 640px) {
  .table_keireki dl {
    display: block !important;
    padding: 10px;
  }
}

.td_logo td {
  border: 1px solid #d5f5fe !important;
}

.sec_05 {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}

@media only screen and (max-width: 640px) {
  .sec_05 {
    display: block;
  }
  .sec_05 .img {
    width: 100% !important;
  }
  .sec_05 .txt {
    width: 100% !important;
  }
}

.sec_05 .img {
  width: 48%;
}

.sec_05 .txt {
  width: 48%;
}

.imgbox {
  justify-content: center;
}

.imgbox .box {
  width: 23%;
  text-align: center;
  margin: 10px;
}

.imgbox .box a {
  color: #333;
}

.imgbox .box h3 {
  font-weight: bold;
  color: #ff0000;
}

.imgbox .box article div {
  font-weight: bold;
}

.imgbox .box img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

@media only screen and (max-width: 640px) {
  .imgbox .box {
    width: 100%;
    margin: 10px;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .imgbox .box {
    width: 47%;
  }
}

.imgbox2 {
  justify-content: center;
}

.imgbox2 .box {
  width: 30%;
  margin: 10px;
}

.imgbox2 .box img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  font-family: 'object-fit: cover;';
}

.imgbox2 .box h3 {
  display: none;
}

@media only screen and (max-width: 640px) {
  .imgbox2 .box {
    width: 100%;
  }
}

.imgbox2 .box div {
  text-align: center;
  font-weight: bold;
  font-size: 1.25em;
}

.gmap iframe {
  width: 100%;
}

.bg_100per_wrap {
  position: relative;
  padding: 360px 0 !important;
}

@media only screen and (max-width: 640px) {
  .bg_100per_wrap {
    padding: 480px 0 !important;
  }
}

@media screen and (min-width: 641px) and (max-width: 834px) {
  .bg_100per_wrap {
    padding: 550px 0 !important;
  }
}

.bg_100per_inner {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: calc(((100vw - 100%) / 2) * -1);
  margin-right: calc(((100vw - 100%) / 2) * -1);
  background: url(../images/contents/cont2_bg.jpg);
  background-size: cover;
  z-index: -1;
}

.modaal-close {
  position: absolute !important;
  right: -20px !important;
  top: -20px !important;
  background: rgba(0, 0, 0, 0.7) !important;
}

.modaal-content-container h3 {
  border-bottom: 2px solid #d5f5fe;
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 15px;
  padding: 0 0 5px;
}

.modaal-content-container p {
  margin: 0 0 20px;
}

.modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
  background: #fff !important;
}

@media only screen and (max-width: 640px) {
  .modaal-content-container {
    height: 400px;
    overflow-y: scroll;
  }
}

.policy {
  padding: 10px 0;
  text-align: center;
}

.policy a {
  color: #033987;
}

.form_file {
  border-top: 1px solid #ccc;
}

.translate {
  display: none;
}

.blog h2 {
  font-size: 1.3em !important;
  border-bottom: 1px solid #033987;
  padding-bottom: 5px !important;
  line-height: 1 !important;
  margin-bottom: 15px;
}

.blog .translate {
  display: block;
}

.blog_list {
  border: none !important;
}

.blog_cont {
  overflow-y: auto;
  height: 260px;
}

.blog_cont .detail {
  display: none;
}

.blog_cont .blog_text {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 640px) {
  .blog_cont .blog_text {
    display: block;
  }
}

.blog_cont .blog_text h3 {
  font-size: 16px !important;
}

.blog_cont .blog_text a {
  color: #333;
}

.blog_cont .blog_text a:hover {
  text-decoration: underline;
  color: #10b5dd;
}

.blog_cont .blog_photo {
  display: none;
}

.blog_cont .blog_date {
  margin-right: 50px;
  color: #333 !important;
  font-size: 16px !important;
}

@media only screen and (max-width: 640px) {
  .blog_cont .blog_date {
    margin-right: 0;
  }
}
